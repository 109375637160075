<template>


  <transition name="slide">

  <div class="view-trip">
   <main-nav />
    <main-titlebar myclass="purpleBar"/>
    <div class="trip-view-wrapper">
         <div v-for="trip in trips" :key="trip.id" class="trip-view-row2">


            <div class="grid-box-vscale">
              <p>Weet u zeker dat u deze rit wilt afsluiten?</p>
            </div>

            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{formatDateTime(trip.etd)}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.origin_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.destination_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col2 textAlignLeft"><i class="fa-solid fa-people-group"></i>Passagiers</div>
              <div class="trip-view-col2 textAlignLeft">  {{ trip.passengers}}</div>
            </div>

            <div class="trip-view-row">
              <div class="trip-view-col2">Aangeboden door</div>
              <div class="trip-view-col2">  {{ trip.provider_name}}</div>
            </div>


            <Form @submit="endTrip" >
          
            <Field name="id" id="id" v-model="trip.id"  type="hidden"/>
            <Field name="gpsloc" id="gpsloc" v-model="curLatLon"  type="hidden"/>
            <!-- <Field v-if="price_set" name="price" id="price" v-model="price"  type="text"/> -->



            <!-- Price -->
            <div class="form-group">
              <div class="col-2  col-2-c trip-view-col2">
                <div class="form-label">
                  <label for="commission">Prijs</label>
                </div>
              </div>
              <div class="col-2  col-2-c trip-view-col2">
                <Field v-if="!price_set" name="price" id="price" class="form-input" v-model="price"  type="text" placeholder="Prijs rit"/>
                <div v-if="price_set"> {{ formatCurrency(trip.price) }}</div>
              </div>
            </div>

            <div class="trip-view-row">

              <!-- price_set = {{price_set}} -->
            </div>



            <!-- Controls -->

            <div class="trip-view-row">
            <div class="trip-view-col">
              <button class="default-button" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Afsluiten</span>
              </button>
            </div>
          </div>




          </Form>
         
          </div>
    </div>
  </div>

    </transition>

</template>
<script>

import TripService from "../../services/trip-service";

import { Form, Field } from "vee-validate";


export default {
  name:'settings',
  data() {
    var status = 'loading'
    var trips;
    var commission;
    var price;
    var price_set = false;
    var markers = [];

    return { 
      curGPSpos:this.currentLocation(),
      trips, 
      loading: false,
      message: "",
      status, 
      markers,
      commission,
      price,
      price_set }
  },
  methods: {
    endTrip(trip) {
      // return id;
      TripService.endTrip(trip).then(
        (response) => {
            this.trips = response.data;     
            this.started = response.data[0]["started"];
            this.te_ts  = response.data[0]["te_ts"];   
            this.openTrip = '';      
            this.showBack = true; 
              this.$router.push('/trips/view/'+trip.id);                      
        },
        (error) => {
          console.log("endTrip error: " + error.toString());
        }
      );     
  
    },
    getCBVal() {
      alert(' getCBVal:'+ this.acceptterms)
    }
    
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    TripService.getTrip(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.trips = response.data;
        if(response.data[0]["price"] > 0) {
            targ.price_set = true;
            targ.price = response.data[0]["price"];
        } 
        console.log("data loaded from: " + JSON.stringify(targ.trips));
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  computed: {
    isPublic() {
      return this.trips[0].public;
    },
    isOwner() {
      var r = false;
      var tripOwner = this.trips[0]["owner"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      // var user  = this.$store.state.auth.user.userid;
      console.log("isOwner: " + tripOwner + " | userid:" + user);
      if(user == tripOwner) {
        r = true;
      }
      return r;
    },
    curLatLon() {
      var curLoc = "";
      var curLocData = JSON.parse(localStorage.getItem('curloc'));
      if(localStorage.getItem('curloc')) {
        curLoc = curLocData.lat + "," + curLocData.lng;
      }
      return curLoc;
    }
  },
  
  components: {
    Form,
    Field,
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>