import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class TripService {
  getOpenTrips() {
    return axios.get(API_URL + 'trips/open',{ headers: authHeader() }).then(response => {
      console.log("getOpenTrips response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        // console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  getMyTrips() {
    return axios.get(API_URL + 'my/trips',{ headers: authHeader() }).then(response => {
      console.log("getMyTrips response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  getMyTripHistory() {
    return axios.get(API_URL + 'my/trips/history',{ headers: authHeader() }).then(response => {
      console.log("getMyTrips response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  createTrip(trip) {
    return axios
    .post(API_URL + 'trips/create', trip,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  saveTrip(trip) {
    return axios
    .post(API_URL + 'trips/save', trip,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  getTrip(id) {
    return axios.get(API_URL + '/trips/'+id,{ headers: authHeader() }).then(response => {
      console.log("getTrip response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  getArchivedTrip(id) {
    return axios.get(API_URL + '/trips/archived/'+id,{ headers: authHeader() }).then(response => {
      console.log("getArchivedTrip response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        localStorage.removeItem('user');
      }
      return response.data;
    });
  }
  offerTrip(trip) {
    return axios
    .post(API_URL + 'trips/offer', trip,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  revokeTrip(trip) {
    return axios
    .post(API_URL + 'trips/revoke', trip,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  acceptTrip(trip) {
    return axios
    .post(API_URL + 'trips/accept', trip,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  startTrip(trip) {
    var curLoc = "";
    var curLocData = JSON.parse(localStorage.getItem('curloc'));
    if(localStorage.getItem('curloc')) {
      curLoc = curLocData.lat + "," + curLocData.lng;
    }
    var postdata = {"id":trip,"loc":curLoc}
    return axios
    .post(API_URL + 'trips/start', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  stopTrip(trip) {
    var curLoc = "";
    var curLocData = JSON.parse(localStorage.getItem('curloc'));
    if(localStorage.getItem('curloc')) {
      curLoc = curLocData.lat + "," + curLocData.lng;
    }
    var postdata = {"id":trip,"loc":curLoc}
    return axios
    .post(API_URL + 'trips/stop', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
    
  }
  endTrip(trip) {
    var postdata = trip;
    return axios
    .post(API_URL + 'trips/end', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
    
  }
  closeTrip(trip) {
    var postdata = trip;
    return axios
    .post(API_URL + 'trips/close', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  cancelTrip(trip) {
    var postdata = trip;
    return axios
    .post(API_URL + 'trips/cancel', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
  deleteTrip(trip) {
    var postdata = trip;
    return axios
    .post(API_URL + 'trips/archived/delete', postdata,{ headers: authHeader() } )
    .then(response => {
      console.log("response: " + JSON.stringify(response.data));
      return response.data;
    });
  }
 
}
export default new TripService();